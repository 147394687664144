import { action, computed, observable, store } from '~/common/mobx.decorator'
import { persist } from 'mobx-persist'
import {
	apolloClient,
	GET_LOOK_UP_ACCOUNT_SCL_QUERY,
	GET_LOOK_UP_SKILL_CHECKLISTS_QUERY,
	GET_WORKER_ACCOUNT_SCL_QUERY,
	GET_WORKER_RESUMES_QUERY,
} from '~/common/apollo'
import { authStore, notifyStore } from '~/stores'
import { RECAPTCHA_CLIENT_KEY } from '~/common/helpers'
import { first, isEmpty } from 'lodash'
import { ADDITION_SKILL_CHECKLIST_STATUS } from '~/common/constants'
import { quickApplyStore } from '~/features/quick-apply/quick-apply.store'
import { getWorkerReferences, UPDATE_ADDITIONAL_DATA } from '~/features/care-activation/care-activation.service'
import { careJobDetailStore } from '~/features/care-jobs/detail/care-job-detail.store'
import { logClickEvent } from '~/common/tracking/event-client.tracking'
import { COMMON_EVENT_CLICK_TRACKING } from '~/common/constants/tracking.constant'
import { GET_SPECITALY_BY_JOB } from '~/common/apollo/queries/job-filter.query'

@store()
class AdditionalInfoApplyDialogStore {
	@observable prevActiveTabValue = ''
	@observable lookupSkillChecklistsByJob = null
	@observable lookupAccountScl = null
	@observable workerSkillChecklists = null
	@observable initValueData = null
	@persist @observable activeTabValue = 'RESUME'
	@observable prevStepIndex = -1
	@observable stepIndex = 0
	@observable stepData = {}
	@observable showSuccessSkillCheckListDialog = false
	@observable percentPerStep = {}
	@observable dirtyData = {}
	@observable displineJobId = []
	@observable jobInfo = {}
	@observable showDialogMatchProfession = false

	@computed
	get steps() {
		return this.lookupSkillChecklistsByJob?.length === 0
			? [
					{
						id: 'resumes',
						name: 'PROFILE_INFORMATION',
						incompleted: '$MESSAGES.PROFILE_INCOMPLETED_DATA',
					},
					{
						id: 'workerReferences',
						name: 'EDUCATION_HISTORY',
						incompleted: '$MESSAGES.EDUCATION_INCOMPLETED_DATA',
					},
			  ]
			: [
					{
						id: 'resumes',
						name: 'PROFILE_INFORMATION',
						incompleted: '$MESSAGES.PROFILE_INCOMPLETED_DATA',
					},
					{
						id: 'workerReferences',
						name: 'EDUCATION_HISTORY',
						incompleted: '$MESSAGES.EDUCATION_INCOMPLETED_DATA',
					},
					{
						id: 'checklist',
						name: 'EDUCATION_HISTORY',
						incompleted: '$MESSAGES.EDUCATION_INCOMPLETED_DATA',
					},
			  ]
	}

	@computed
	get resumes() {
		return this.stepData['resumes'] || []
	}

	@computed
	get references() {
		return this.stepData['workerReferences'] || []
	}

	@action
	resetInitData = () => {
		this.stepData = {}
		this.stepIndex = 0
		this.dirtyData = {}
	}

	@action
	changeActiveStep = (stepIndex, force = false) => {
		if (this.stepIndex === stepIndex) {
			return
		}

		this.prevStepIndex = this.stepIndex
		this.stepIndex = stepIndex
	}

	@action
	syncStepData = async (key, formData, dirty = true) => {
		this.dirtyData[key] = dirty
		this.stepData[key] = formData
	}

	@action
	updateProfileAdditionInfo = async (values) => {
		const { workerReferences, resumes } = values || {}
		try {
			await apolloClient.mutate({
				mutation: UPDATE_ADDITIONAL_DATA,
				variables: {
					workerReferences,
					resumes,
				},
			})
		} catch (error) {
			notifyStore.error(error.message)
		}
	}

	@action
	handleAdditionSubmitJobAuth = async () => {
		const { currentJobID } = quickApplyStore
		await this.updateProfileAdditionInfo({
			resumes:
				this.stepData['resumes']
					?.filter((item) => item.signedBlobId && !item._destroy && !item.parentId)
					?.map((item) => ({
						fileSignedBlobId: item.signedBlobId,
					})) || [],
			workerReferences: this.stepData['workerReferences']?.map((item) => {
				return {
					id: item.id,
					email: item.email,
					firstName: item.firstName,
					lastName: item.lastName,
					phone: item.phone,
					relationship: item.relationship,
					jobTitle: item.jobTitle,
					state: !isEmpty(item?.state) ? item?.state : null,
					city: item?.city?.name ?? null,
				}
			}),
		})
		await careJobDetailStore.actionApplyJob().then((res) => {
			careJobDetailStore.setJobItem(res)
		})
		await quickApplyStore.setShowAdditionInfo(false)
		await logClickEvent(COMMON_EVENT_CLICK_TRACKING.completeBtn, {
			job_id: currentJobID,
			is_account_created: false, // false HERE
			step_id: this.stepIndex, // 1 HERE. Step 1, 2, OR 3 according to Figma
			has_una: !isEmpty(this.fetchWorkerSclProvider), // null HERE. whether user's email has associated UNA account or not
			has_skills_assigned: !isEmpty(this.lookupSkillChecklistsByJob), // null HERE
		})
	}

	@action
	handleSubmitStepData = async (key, formData) => {
		const { currentJobID } = quickApplyStore

		try {
			await this.handleSubmitStep(key, formData)
			if (formData) {
				this.stepData[key] = formData
				this.percentPerStep[key] = 100
			} else {
				this.percentPerStep[key] = 0
			}
			if (key === 'checklist' && this.lookupSkillChecklistsByJob?.length !== 0) {
				if (authStore.id) {
					if (!this.percentPerStep.hasOwnProperty('resumes')) {
						//Check empty resume move step 0 login
						this.changeActiveStep(0)
					} else if (!this.percentPerStep.hasOwnProperty('workerReferences')) {
						//Check empty referral move step 0 login
						this.changeActiveStep(1)
					} else {
						await this.handleAdditionSubmitJobAuth()
					}
				} else {
					if (!this.percentPerStep.hasOwnProperty('resumes')) {
						//Check empty resume move step 0 ano
						this.changeActiveStep(0)
					} else if (!this.percentPerStep.hasOwnProperty('workerReferences')) {
						//Check empty referral move step 0 ano
						this.changeActiveStep(1)
					} else {
						this.setShowSuccessSkillCheckListDialog(true)
					}
				}
			} else if (key === 'workerReferences' && this.lookupSkillChecklistsByJob?.length === 0) {
				if (authStore.id) {
					if (this.percentPerStep.hasOwnProperty('resumes')) {
						await this.handleAdditionSubmitJobAuth()
					} else {
						this.changeActiveStep(0)
					}
				} else {
					if (this.percentPerStep.hasOwnProperty('resumes')) {
						await quickApplyStore.quickApplyJobs(
							{
								...quickApplyStore.applyJobData,
								resume: this.stepData['resumes'],
								references: this.stepData['workerReferences'],
							},
							{},
							() => quickApplyStore.setShowAdditionInfo(false)
						)
					} else {
						this.changeActiveStep(0)
					}
				}
			} else {
				this.moveNextStep()
			}
			if (key === 'resumes') {
				logClickEvent(COMMON_EVENT_CLICK_TRACKING.uploadResumeBtn, {
					job_id: currentJobID,
					is_account_created: false, // false HERE
					step_id: this.stepIndex, // 1 HERE. Step 1, 2, OR 3 according to Figma
					has_una: !isEmpty(this.fetchWorkerSclProvider), // null HERE. whether user's email has associated UNA account or not
					has_skills_assigned: !isEmpty(this.lookupSkillChecklistsByJob), // null HERE
				})
			}

			logClickEvent(COMMON_EVENT_CLICK_TRACKING.nextStepBtn, {
				job_id: currentJobID,
				is_account_created: false, // false HERE
				step_id: this.stepIndex, // 1 HERE. Step 1, 2, OR 3 according to Figma
				has_una: !isEmpty(this.fetchWorkerSclProvider), // null HERE. whether user's email has associated UNA account or not
				has_skills_assigned: !isEmpty(this.lookupSkillChecklistsByJob), // null HERE
			})
		} catch (error) {
			notifyStore.error(error?.message)
		}
	}

	@action
	handleSubmitStep = async (key, formData) => {
		this.stepData[key] = formData
	}

	@action
	moveNextStep = () => {
		this.changeActiveStep(this.stepIndex + 1)
	}

	@action
	changeActiveStepAndSubmit = async (stepIndex) => {
		const step = this.steps[this.stepIndex]
		const formData = this.stepData[step?.id]
		this.changeActiveStep(stepIndex)
		if (formData) {
			await this.handleSubmitStep(step?.id, formData)
		}
	}

	@computed
	get slideDirection() {
		return this.stepIndex > this.prevStepIndex ? 'left' : 'right'
	}

	@computed
	get activeStep() {
		return this.steps?.[this.stepIndex]
	}

	@action
	fetchLookupSkillChecklistsByJob = async (jobId) => {
		try {
			const response = await apolloClient.query({
				query: GET_LOOK_UP_SKILL_CHECKLISTS_QUERY,
				variables: {
					jobId,
				},
				context: {
					clientName: authStore.id ? null : 'public',
				},
			})

			const dataSCL = response?.data?.lookupSkillChecklistsByJob

			this.lookupSkillChecklistsByJob = authStore.id
				? dataSCL?.map((item) => {
						const statusItem = isEmpty(item?.workerSkillChecklist)
							? ADDITION_SKILL_CHECKLIST_STATUS.notAssigned
							: item?.workerSkillChecklist?.completed === true
							? ADDITION_SKILL_CHECKLIST_STATUS.completed
							: ADDITION_SKILL_CHECKLIST_STATUS.assigned
						return {
							...item,
							status: statusItem,
						}
				  })
				: dataSCL?.map((item) => {
						return {
							...item,
							status: ADDITION_SKILL_CHECKLIST_STATUS.notAssigned,
						}
				  })
		} catch (error) {
			await notifyStore.error(error.message)
		}
	}

	@action
	fetchLookupAccountScl = async (variables) => {
		return new Promise((resolve, reject) => {
			window.grecaptcha.ready(async () => {
				try {
					const token = await window.grecaptcha.execute(RECAPTCHA_CLIENT_KEY, {
						action: 'lookupAccountScl',
					})
					const { email } = variables || {}

					let updatedVariables = {
						recaptchaToken: token,
						email,
					}
					const { data } = await apolloClient.mutate({
						mutation: GET_LOOK_UP_ACCOUNT_SCL_QUERY,
						variables: { ...updatedVariables },
						context: {
							clientName: authStore.id ? null : 'public',
						},
					})
					this.lookupAccountScl = data?.lookupAccountScl
					resolve()
				} catch (error) {
					await notifyStore.error(error.message)
					reject(error)
				}
			})
		})
	}

	@action
	fetchWorkerSkillChecklists = async (variables) => {
		return new Promise((resolve, reject) => {
			window.grecaptcha.ready(async () => {
				try {
					const token = await window.grecaptcha.execute(RECAPTCHA_CLIENT_KEY, {
						action: 'fetchWorkerSclProvider',
					})
					const { providerAccount } = variables || {}

					let updatedVariables = {
						recaptchaToken: token,
						providerAccount,
					}
					const { data } = await apolloClient.mutate({
						mutation: GET_WORKER_ACCOUNT_SCL_QUERY,
						variables: { ...updatedVariables },
						context: {
							clientName: authStore.id ? null : 'public',
						},
					})
					this.fetchWorkerSclProvider = data?.fetchWorkerSclProvider
					if (!isEmpty(data?.fetchWorkerSclProvider)) {
						this.lookupSkillChecklistsByJob = this.lookupSkillChecklistsByJob?.map((item) => {
							const itemMatch = data?.fetchWorkerSclProvider?.workerSkillChecklists?.find((scl) => scl?.skill_checklist_id === parseInt(item?.providerId))
								?.completed

							if (itemMatch === true) {
								return {
									...item,
									status: ADDITION_SKILL_CHECKLIST_STATUS.completed,
								}
							} else if (itemMatch === false) {
								return {
									...item,
									status: ADDITION_SKILL_CHECKLIST_STATUS.assigned,
								}
							} else {
								return item
							}
						})
					}
					resolve()
				} catch (error) {
					await notifyStore.error(error.message)
					reject(error)
				}
			})
		})
	}

	@action
	changeActiveTabValue = (tabValue) => {
		if (this.activeTabValue === tabValue) {
			return
		}

		this.activeTabValue = tabValue
	}

	@action
	setShowSuccessSkillCheckListDialog = (values) => {
		this.showSuccessSkillCheckListDialog = values
	}
	@action
	handleRemoveResumeFile = (values) => {
		if (!authStore.id) {
			this.stepData['resumes'] = []
		}
	}

	@action
	fetchWorkerReferences = async () => {
		const { workerReferences } = await getWorkerReferences(authStore.id)
		this.stepData['workerReferences'] =
			workerReferences?.map(({ referenceType, jobTitle, city, state, country, phone, ...rest }) => {
				// Regular expression to match the phone number pattern
				const phoneRegex = /\((\d{3})\) (\d{3})-(\d{4})/
				let transformedPhone = phone

				// Check if the phone matches the pattern
				const match = phone.match(phoneRegex)
				if (match) {
					// Construct the phone number in the new format
					transformedPhone = `+1${match[1]}${match[2]}${match[3]}`
				}

				return {
					...rest,
					jobTitle: jobTitle,
					location: city && state ? { country, stateCode: state, name: city } : null,
					phone: transformedPhone, // Use the transformed phone number
					city: city,
					state: state,
				}
			}) || []
	}

	@action
	fetchWorkerResumes = async () => {
		const { data } = await apolloClient.query({
			query: GET_WORKER_RESUMES_QUERY,
		})

		this.stepData['resumes'] =
			[
				first(
					data?.worker?.resumes?.map((item) => {
						return {
							...item.file,
							parentId: item.id,
						}
					})
				),
			] || []
	}

	@action
	fetchDisplineJob = async () => {
		const { data } = await apolloClient.query({
			query: GET_SPECITALY_BY_JOB,
			variables: {
				id: quickApplyStore.currentJobID,
			},
		})
		this.jobInfo = data?.job
		this.displineJobId = data?.job?.jobGeneral?.specialties.map((item) => item?.discipline?.id) || []
	}

	@action
	changeNextTab = (tabValue) => {
		if (this.activeTabValue === 'RESUME') {
			this.activeTabValue = 'REFERENCE'
		} else if (this.activeTabValue === 'REFERENCE') {
			this.activeTabValue = 'SKILL_CHECKLIST'
		} else {
			this.activeTabValue = 'RESUME'
		}
	}

	@action
	setShowDialogMatchProfession = (value) => {
		this.showDialogMatchProfession = value
	}
}

export const additionalInfoApplyDialogStore = new AdditionalInfoApplyDialogStore()
