import React, { useEffect } from 'react'
import { CircularProgress } from '@material-ui/core'
import { indeedApplyStore } from './Indeed-apply.store'
import { useHistory } from 'react-router-dom'
import { PROVIDER_NAME } from '~/common/constants'
import { handleLoadReCaptcha } from '~/common/helpers'

export const IndeedCallback = () => {
	const history = useHistory()
	const { handleSuccess } = indeedApplyStore

	useEffect(() => {
		const handleCallback = async () => {
			const urlParams = new URLSearchParams(window.location.search)
			const code = urlParams.get('code')
			const state = urlParams.get('state')
			if (state) {
				const jobId = state.split('_jobId_')?.[1]
				await indeedApplyStore.setJob({ id: jobId })
			}

			await handleLoadReCaptcha()

			if (code) {
				try {
					const data = {
						clientId: process.env.REACT_APP_INDEED_CLIENT_ID,
						providerName: PROVIDER_NAME.indeed,
						code,
						redirectUri: `${window.location.origin}/indeed`,
					}
					await handleSuccess(data, history)
				} catch (error) {
					console.error('Error handling Indeed callback:', error)
				}
			}
		}

		handleCallback()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
			<CircularProgress />
		</div>
	)
}

export default IndeedCallback
