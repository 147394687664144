export const PHONE_FORMAT = '(###) ###-####'
export const SHORT_FREQUENCY_OPTIONS = {
	daily: 'Dly',
	hourly: 'Hr',
	monthly: 'Mth',
	yearly: 'Yr',
	weekly: 'Wk',
}

export const BADGE_TAB = {
	earned: 'earned',
	all: 'all',
}

export const CREDENTIALS_COMPLETE_TYPE = {
	external: 'external',
	in_app: 'in_app',
}

export const CREDENTIALS_STATUS = {
	pending: 'pending',
	in_verification: 'in_verification',
	verified: 'verified',
	expired: 'expired',
	signed: 'signed',
}

export const CREDENTIALS_ATS_SOURCE = {
	bullhorn: 'bullhorn',
	symplr: 'symplr',
}

export const SOCIAL_PROFIDER_MAPPING = {
	google: 'Google',
	facebook: 'Facebook',
	apple: 'Apple ID',
	linkedin: 'LinkedIn',
	indeed: 'Indeed',
}

export const NOTIFICATION_EVENT_CODE = {
	credential_incompleted: 'credential_incompleted',
	credential_new_request: 'credential_new_request',
	credential_expired_soon: 'credential_expired_soon',
	job_matches: 'job_matches',
	assignment_check_out: 'assignment_check_out',
	job_cancellation: 'job_cancellation',
	job_rating: 'job_rating',
	job_alert: 'job_alert',
	timecard_submission: 'timecard_submission',
	job_applicant_status_changed: 'job_applicant_status_changed',
}
export const NOTIFICATION_OBJECT_TYPE = {
	job: 'Job',
}

export const ADDITION_SKILL_CHECKLIST_STATUS = {
	completed: 'completed',
	assigned: 'assigned',
	notAssigned: 'not_assigned',
}

export const SORT_TYPE = {
	asc: 'asc',
	desc: 'desc',
}

export const LIMIT_PAGE = 24

//JOB STATE
export const JOB_PLACEMENT_STATUS = {
	pending: 'pending',
	pending_orientation: 'pending_orientation',
	orientation_confirmed: 'orientation_confirmed',
	availability_needed: 'availability_needed',
	pending_confirmation: 'pending_confirmation',
	accepted: 'accepted',
	confirmed: 'confirmed',
	cancelled: 'cancelled',
	completed: 'completed',
}

export const ALLOWED_SEARCH_FILTER_KEYS = [
	'keyword',
	'disciplinesCategories',
	'disciplineIds',
	'specialtyIds',
	'addressStates',
	'addressCities',
	'nearBy',
	'currentLocation',
	'workTypes',
	'shifts',
	'settings',
	'payAmountMin',
	'payAmountMax',
	'minMatchingPercentage',
	'hotJob',
	'createdBetween',
	'startDatetimeBetween',
	'isPerDiem',
	'isPerDiemLongTerm',
	'externalFriendlyId',
]
