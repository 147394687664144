export const MASTER_DATA_OBJECTS = {
	Worker: 'Worker',
	WorkExperienceOverview: 'WorkExperienceOverview',
	WorkerCertification: 'WorkerCertification',
	WorkExperience: 'WorkExperience',
	WorkerExpense: 'WorkerExpense',
	Job: 'Job',
}

export const MASTER_DATA_FIELDS = {
	discipline: 'discipline',
	job_discipline: 'job_discipline',
	worker_discipline: 'worker_discipline',
	specialty: 'specialty',
	primarySpecialty: 'primary_specialty',
	secondarySpecialty: 'secondary_specialty',
	licenseState: 'state',
	preferredLocations: 'preferred_locations',
	unitSpecialty: 'unit_specialty',
	agency: 'agency',
	referral: 'referral_method',
	charting: 'charting_software_used',
	contactRelationship: 'contact_relationship',
	category: 'category',
	shifts: 'shifts',
	documentType: 'support_document',
	state: 'state',
	jobType: 'employment_types',
	facilityTypes: 'facility_types',
	educationDegrees: 'education_degrees',
	timezoneIdentifiers: 'timezone_identifiers',
	referenceRelationship: 'reference_relationship',
	savedFilterFrequencies: 'saved_filter_frequencies',
	savedFilterPauseAlertPeriods: 'saved_filter_pause_alert_periods',
	savedFilterNotificationChannels: 'saved_filter_notification_channels',
	worked_shifts_break_times: 'worked_shifts_break_times',
	disciplinesCategories: 'disciplines_categories',
	reasons_for_leaving: 'reasons_for_leaving',
	jobEmploymentTypes: 'job_employment_types',
}
